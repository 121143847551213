import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import predict_instructions from '../downloads/redcap_instructions.pdf'
import predict_protocol from '../downloads/PanSurg_PREDICT_Protocol.pdf'
import logo from '../images/logo_predict.svg'
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import map from '../images/predictmap.png'
import predictendo from '../images/predictendo.png'
import predictortho from '../downloads/PREDICT-ORTHO.pdf'



export const query = graphql`
  query {
    hero: file(relativePath: { eq: "tower_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({data}) => {
  return (
  <>
<GatsbySeo title='PanSurg PREDICT'
      description='Realtime risk prediction models to aid surgical decision-making during the COVID-19 pandemic.'/>
  <Hero title="PanSurg PREDICT"
  subtitle="Realtime risk prediction models to aid surgical decision-making during the COVID-19 pandemic"
        fluid={data.hero.childImageSharp.fluid}
        buttonTxt = "Register"
        buttonLink= "https://redcap.imperial.ac.uk/surveys/index.php?s=49WFNYKAKE"
        breadcrumbs
        secondcrumb = 'Research'
        secondlink = '/research'
        activecrumb = 'PREDICT'
  />

  <section className="section" style={{marginTop:'1rem'}}>
    <div className="container">
      <div className="is-thin">

      <div className="has-text-centered">
        <img src={logo} width="50%" alt="PREDICT Logo" style={{marginBottom:'1rem'}}/>
        <img src={map} width="50%" alt="PREDICT map" style={{marginBottom:'1rem', padding:'1rem'}}/>
      </div>

      <div className="content">

        <p>In the midst of the COVID-19 pandemic, patients with or without COVID-19
        will continue to present to hospital with surgical pathology in a working environment depleted of resources.
        These patients need careful consideration regarding admission and potential operative management.
        Therefore, there is an urgent need for dynamic risk prediction tools which are specific to this particularly trying context.</p>

        <p><b>PanSurg PREDICT will collect, analyse and interpret global in-hospital data regarding:
        </b></p>
          <ul>
            <li>The management of patients presenting with surgical pathology in the context of the COVID-19 pandemic,</li>
            <li>Their associated outcomes,</li>
            <li>How this may influence workforce planning during and after the pandemic.</li>
          </ul>

          </div>

        <div className="buttons" style={{justifyContent:'space-around'}}>
          <a className="button is-red is-rounded"
          href="https://redcap.imperial.ac.uk/surveys/index.php?s=49WFNYKAKE">Register</a>

          <a className="button is-darkBlue is-rounded"
          href={predict_protocol}
          download="PanSurg_PREDICT_Protocol.pdf">Study protocol</a>

          <a className="button is-darkBlue is-rounded"
          href={predict_instructions}
          download="PanSurg_redcap_instructions.pdf">Redcap instructions</a>

        </div>
        <div className="columns">
          <div className="column">
          <div className="notification ">
                        <h3 className="subtitle is-size-5">UPDATE! PREDICT - CARDIAC</h3>                         
          <div>
                        <p style={{float:"none"}}>We are pleased to announce the PREDICT-Cardiac extension is now live. 
                          </p>   
                          </div>       
                          </div>
        <div className="notification ">
                        <h3 className="subtitle is-size-5">PREDICT - ENDOCRINE</h3>                         
          <div>
          <img src={predictendo} width="40%" alt="PREDICT endo" style={{float:"left", marginRight:"1rem"}}/>
                        <p style={{float:"none"}}>We are pleased to announce the PREDICT-Endocrine extension is now live. 
                          We are inviting all endocrine surgeons globally to input data about their patients, 
                          including those presenting with thyroid, parathyroid and adrenal pathology.
                          </p> 
                          <p><b>
                          PREDICT-ENDOCRINE will continue to recruit retrospectively and prospectively until the 13th September 2020.</b></p>  
                          </div>       
                          </div>
              </div>
              <div className="column">
              <div className="notification ">
                        <h3 className="subtitle is-size-5">UPDATE! PREDICT - THORACIC</h3>                         
          <div>
                        <p style={{float:"none"}}>We are pleased to announce the PREDICT-Thoracic extension is now live. 
                          </p>   
                          </div>       
                          </div>
              <div className="notification">
              <h3 className="subtitle is-size-5">PREDICT - ORTHOPAEDICS</h3>
              <p>Study period (contact us for specific dates if outside the UK) Period 1: For UK, start of lockdown from 23rd March to 20th April 2020 Period 2: For UK, end of lockdown from 4th July to 1st August 2020 Deadline: For UK, 30th September 2020</p>
              <p>We invite all T&O surgeons to participate in our study and include all acute and elective referrals and operative case mix for orthopaedic trauma in both central Trauma Centres and peripheral hospitals, major orthopaedic trauma and open fractures, Paediatric orthopaedics and Geriatric fragility neck of femur fractures.
                          </p> <br />
                          <a className="button is-darkBlue is-rounded"
          href={predictortho}
          download="PREDICT-ORTHO.pdf">Predict-Ortho Sheet</a> 
              </div>
              </div>
              
</div>
        </div>
      </div>
    </section>

    <section className="section is-small">
      <div className="container">
        <div className="is-thin">

        <h1 className="title is-size-4">Methodology</h1>

              <div className="content is-size-6">

                <p>We aim to build a novel risk prediction tools to provide surgeons with
                more accurate estimates regarding the potential risk of death and
                complications in their patients, with or without COVID-19.
                Realtime guidance will be an invaluable aid to inform decision-making by
                surgeons working on the frontline during this pandemic.</p>

                    <h2 className="subtitle is-size-5">Weekly Surgical Service Surveys</h2>

                      <p>In the first instance, we are collecting realtime data about the strain
                      surgical departments are under, in light of current re-deployment of staff and
                      re-allocation of wards and operating theatres. This data collection can be performed
                      immediately after registration and you will receive a weekly survey which we suggest can
                      be completed quickly at handover.</p>

                      <p>These surveys will allow us to incorporate the strain on services into our
                        risk-predictor calculators to produce realtime results for your patients in your hospital.</p>
                    
                      <p>Completion of weekly service surveys for your departments is already open for all surgical specialties.
                        To register and start completing our quick weekly service surveys please use the link above.</p>

                      <h2 className="subtitle is-size-5">PREDICT</h2>

                    <p>Although <b>prospective</b> patient recruitment for has <b>closed</b>, if you have capacity, you can choose to <b>continue using Redcap</b> to capture your local data, especially if confronting <b>new outbreaks</b>, or simply in order to help you with regional planning/research or maintain a repository for your own audit purposes.
For the purposes of PREDICT, we are asking all teams to please <b>ensure validation</b> of retrospective data for the whole period <b>between 11th March and 17th August, by midnight on the 24th August.</b> </p>

                        <p>Patient data collection will include details of presentation,
                        past medical history, management and operative assessment, surgery
                        and outcomes. This will be performed on REDCAP, a popular data
                        collection tool for clinical research which is fully GDPR compliant.</p>

                        <p>Patient data collection will require approval from your local
                        Research and Development (R&D) departments within your trust or hospital.
                        As the study is aimed at a national and international level, ethical
                        approvals may vary according to local Research and Development departments.</p>

                        <p>Please use the protocol provided to help complete your application
                        for expedited ethics approval with your local R&D department. Once R&D
                        approval is in place, your contribution with up-to-date data collection
                        is crucial.</p>

                        <p>Patient recruitment and data entry is currently open for general, endocrine
                        and vascular surgery. Data collection protocols for other specialties,
                        are under development and if you have registered under those specialties we will notify you
                        as soon as they are ready to go live.</p>

                        <p>This is a crucial time for us around the world in all
                        fields of healthcare and your help is vital to collect as
                        much data as possible in order to improve patient safety and outcomes.</p>
                        <p>We thank you very much in advance for your contribution.</p>
                  
                </div>
              </div>
            </div>
          </section>


  </>
  )
}
